import React from "react"

/**
 * A wrapper around <trailguide-plugin>
 * @content - The data content query
 */
export function TrailguidePlugin(props = {}) {
  let { content = "", license, ...rest } = props
  if (content.indexOf("category") === -1 && content.indexOf("/x") === -1) {
    content = content + "&category=trail"
  }
  return (
    <div className="my-12" align="center">
      <trailguide-plugin
        license={license || "6197b300e7f81914e400e3b7"}
        content={content}
        {...rest}
      ></trailguide-plugin>
    </div>
  )
}

/**
 * An iframe containing trailguide.
 * @pos - the position value (zoom,lat,lng)
 * @map - the map to be used (cyclemap, norgeskart, google)
 * @code - the code of a custom map (for instance 1a)
 */
export function TrailguidePluginOld(props) {
  return (
    <div
      data-element="trailguide-plugin-container"
      className="my-12 h-96 md:h-128"
      style={{
        ...(props.height && { height: props.height }),
        maxHeight: "600px",
      }}
    >
      <iframe
        title="trailguide"
        className="my-12 shadow rounded border border-gray-400 h-full"
        src={src(defaultProps(props))}
        width={props.width || "100%"}
      />
    </div>
  )
}

function src(props) {
  return `https://trailguide.net/${params(props)}`.trim()
}

function params(props) {
  if (props.code) {
    return "#" + props.code + values(props)
  } else {
    return values(props)
  }
}

function values(props) {
  return (
    "?position=0" +
    Object.keys(props)
      .map((key) => `&${key}=${props[key]}`)
      .join("")
  )
}

function defaultProps(props) {
  return {
    hideList: 0,
    top: 0,
    position: 0,
    scrollWheelZoom: 0,
    doubleClickZoom: 0,
    ...props,
  }
}
