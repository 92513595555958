import { Link } from "gatsby";
import { Image, Video, IPhone, img } from "../../../../src/components";
import * as React from 'react';
export default {
  Link,
  Image,
  Video,
  IPhone,
  img,
  React
};