import React from "react"

/**
 * Wraps an svg icon.
 */
export function Svg(props) {
  return <span>{useCurrentColor(props.children || props.icon)}</span>
}

function useCurrentColor(Component) {
  if (typeof Component === "function") {
    return clone(<Component />, { className: "fill-current" })
  } else {
    return clone(Component, { className: "fill-current" })
  }
}

function clone(Component, extraProps) {
  return <Component.type {...Component.props} {...extraProps} />
}
