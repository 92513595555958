import React from "react"
import { Link } from "gatsby"

export function FindOutMoreButton(props) {
  const classes = `
    bg-green-600 hover:bg-green-700 text-white text-sm sm:text-base
    py-2 px-4 sm:px-6 rounded shadow inline-block select-none cursor-pointer
    ${props.className}
  `.trim()

  if (props.href) {
    return (
      <a className={classes} href={props.href} target={props.target}>
        <div className="flex items-center h-full">{props.children || ""}</div>
      </a>
    )
  } else if (props.to) {
    return (
      <Link className={classes} to={props.to}>
        <div className="flex items-center h-full">{props.children || ""}</div>
      </Link>
    )
  } else {
    return null
  }
}
