import React from "react"

export function Section(props) {
  const { className = "" } = props
  const classes = `
    my-6 sm:my-8 md:my-12 clearfix
    ${className}
  `.trim()
  return <div className={classes} />
}

export function PageSection(props = {}) {
  let classes = "p-6 pt-12 bg-gradient-to-b from-gray-100 to-white"
  if (props.className) {
    classes += ` ${props.className}`
  }
  return (
    <div id={props.id} className={classes}>
      <div className="mx-auto max-w-4xl">{props.children}</div>
    </div>
  )
}
