import React from "react"
import { ImMobile } from "react-icons/im"
import QRCode from "qrcode-svg"

export function AvailableAsWebApp(props) {
  let qrcode = new QRCode({
    content: props.url || "https://trailguide.net",
    container: "svg-viewbox",
    padding: 0,
    join: true,
  }).svg()
  qrcode = qrcode.substring(qrcode.indexOf("<svg"))

  const style = props.style || {}

  return (
    <div className="inline-block" style={style}>
      <div className="flex justify-center items-center bg-gray-900 rounded-l-md text-white">
        <div className="p-1 flex justify-center items-center">
          <ImMobile className="text-4xl mr-2" />
          <div>
            <div className="text-xs leading-tight">available as</div>
            <div className="text-xl leading-tight tracking-wider">WebApp</div>
          </div>
        </div>
        <div
          className="ml-4 w-14"
          dangerouslySetInnerHTML={{ __html: qrcode }}
        ></div>
      </div>
      {props.label && (
        <div className="text-xs ml-1 text-left text-black">{props.label}</div>
      )}
    </div>
  )
}
