import React from "react"
import { Link } from "gatsby"

export function Next(props) {
  return (
    <div className={`right-0 ${nextPrevStyle}`}>
      <Link className="float-right text-white" {...props}>
        →
      </Link>
    </div>
  )
}

export function Prev(props) {
  return (
    <div className={`left-0 ${nextPrevStyle}`}>
      <Link className="float-right text-white" {...props}>
        ←
      </Link>
    </div>
  )
}

const nextPrevStyle = `
  w-8 h-8 rounded-full bg-white-50 text-xl font-bold
  hidden sm:flex justify-center items-center
  absolute z-50 flex bottom-0 m-4
`.trim()
