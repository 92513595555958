import React from "react"

/**
 * A youtube video.
 */
export function Video(props) {
  const { src, className = "", ...rest } = props
  return (
    <div className={`my-8 tg-video-container ${className}`.trim()} {...rest}>
      <iframe
        className="rounded shadow"
        title="youtube movie"
        src={`https://www.youtube.com/embed/${src}?rel=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      />
    </div>
  )
}
