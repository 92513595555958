import React, { useState } from "react"
import { Link } from "gatsby"

import Seo from "./seo"
import { Home } from "./home"
import { Layout } from "./layout_default"
import { backgroundClasses } from "./core"
import { BlogHeader } from "./BlogHeader"

export function SideBarLayout(props) {
  const { pageContext, children, sidebar } = props
  const { frontmatter } = pageContext

  const { root = "" } = sidebar

  const active = props.path?.substring?.(root.length + 2)?.replace("/", "")
  const items = Object.entries(sidebar.items)

  const [isVisible, setVisible] = useState(false)
  const open = isVisible ? "" : "-ml-64"

  return (
    <Layout
      home="sm:text-blue-100"
      className={backgroundClasses(props)}
      path={props.path}
      footer="sm:ml-64"
    >
      <Seo
        children={props.children}
        page={frontmatter}
        title={frontmatter.title || frontmatter.heading}
      />
      <nav
        className={`fixed left-0 top-0 bottom-0 w-64 bg-gray-700 z-50 ${open} sm:ml-0`}
      >
        <button
          className="sm:hidden absolute bg-gray-200 right-0 w-6 h-10 rounded-r mt-14 -mr-6 flex justify-center items-center text-xl text-gray-800"
          onClick={() => setVisible(!isVisible)}
          onKeyDown={() => setVisible(!isVisible)}
        >
          {isVisible ? "‹" : "›"}
        </button>
        <SideBar active={active} items={items} root={sidebar.root} />
      </nav>
      <main className="tg-main tg-news mb-16 max-w-4xl sm:ml-64 m-auto px-4 sm:px-6 md:px-12">
        <BlogHeader path={props.path} {...frontmatter} />
        {children}
      </main>
    </Layout>
  )
}

function SideBar(props) {
  return (
    <div className="h-full bg-gray-700 pb-8 overflow-y-auto">
      <div className="inline-block h-20 pt-2">
        <Home className="text-blue-100" />
      </div>
      {props.items.map(item => (
        <MenuItem key={item[0]} document={item[0]} {...props}>
          {item[1]}
        </MenuItem>
      ))}
    </div>
  )
}

function MenuItem(props) {
  const { document, active, root } = props

  const link = "block px-6 py-3 leading-snug text-gray-300 hover:bg-gray-600"
  const bg = a => link + " " + (a ? "bg-gray-800" : "")

  return (
    <Link className={bg(active === document)} to={`/${root}/${document}`}>
      {props.children}
    </Link>
  )
}
