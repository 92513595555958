import { Link } from "gatsby";
import { BlogEntry } from "../../../../src/components";
import { DesktopRightImage, PhoneImage } from "../../../../src/components";
import * as React from 'react';
export default {
  Link,
  BlogEntry,
  DesktopRightImage,
  PhoneImage,
  React
};