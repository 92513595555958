import React from "react"

import { Link } from "gatsby"

import { Svg } from "../components/icon"
import Logo from "../icons/logo.svg"

export function Home(props) {
  return props.language === "no" ? (
    <HomeDefault {...props} to="/no">
      DIGITALISER TURISMEN
    </HomeDefault>
  ) : (
    <HomeDefault {...props}>DIGITALIZING TOURISM</HomeDefault>
  )
}

function HomeDefault(props) {
  const { className = "" } = props
  return (
    <Link
      to={props.to || "/"}
      className={`
        h-full ml-3 sm:ml-6 items-center
        border-b-4 border-transparent hover:border-orange-400
        ${className}
      `.trim()}
    >
      <span className={`inline-block w-8 h-8 mr-2`}>
        <Svg icon={Logo} />
      </span>
      <div className="inline-block select-none">
        <div
          className="inline-block font-bold mt-1"
          style={{
            fontSize: "21px",
          }}
        >
          TRAILGUIDE
        </div>
        <div
          className="relative ml-px whitespace-no-wrap"
          style={{ top: "-7px", fontSize: "10.5px" }}
        >
          {props.children}
        </div>
      </div>
    </Link>
  )
}
