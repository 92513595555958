import { Section } from "../../../../src/layout";
import { Image, Gallery, Thumb, TrailguidePlugin } from "../../../../src/components";
import * as React from 'react';
export default {
  Section,
  Image,
  Gallery,
  Thumb,
  TrailguidePlugin,
  React
};