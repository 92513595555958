import Seo from "./seo"
export { Seo }
export * from "./core"
export * from "./layout_default"
export * from "./BlogHeader"
export * from "./ByLine"
export * from "./FlexHeightSection"
export * from "./footer"
export * from "./header"
export * from "./NextPrev"
export * from "./Section"
export * from "./SectionHeading"
export * from "./seo"
export * from "./SideBarLayout"

export const classes = {
  section: "p-6 bg-gradient-to-b from-gray-100 to-white",
  sectionText: "flex flex-wrap sm:flex-no-wrap items-start my-6 md:my-12",
  statement: "px-6 pb-16 max-w-2xl mx-auto leading-7 text-sm sm:text-base",
  heading: "text-xl sm:text-2xl md:text-3xl lg:text-4xl text-left",
  heading2: "text-xl md:text-2xl lg:text-3xl text-left",
  heading3: "text-base md:text-xl lg:text-2xl text-left",
  paragraph: "text-base sm:text-lg md:text-xl my-4 md:my-6",
  iphone: "flex-none w-64 mb-4 sm:ml-12 mt-4 mx-4",
  imageRight: "flex-none w-full sm:w-1/2 sm:ml-8 mt-8 max-w-lg rounded shadow",
  imageRightNoShadow: "flex-none w-full sm:w-1/2 sm:ml-8 mt-8 max-w-lg",
  imageLeft: "flex-none w-full sm:w-1/2 sm:mr-8 mt-8 max-w-lg rounded shadow",
}

let img

export function randomImg() {
  // if (img != null) {
  //   return img
  // } else {
  const src = images[Math.floor(Math.random() * images.length)]
  img = `https://res.cloudinary.com/trailguide-as/image/upload/c_limit,dpr_auto,w_auto:100:1200/v1590680706/destinations/${src}`
  return img
  // }
}

const images = [
  "sandane_7",
  "sandane_6",
  "granada_8",
  "granada_7",
  "Malaga_13",
  "valmustair_21",
  "valmustair_20",
  "valmustair_10",
  "flimslaaxruncatrail",
  "paganella_3",
  "paganella_1",
  "canazei_1",
  "zermatt_4",
  "slovenia2",
  "slovenia1",
  "stmoritz_8",
  "stmoritz_3",
  "stmoritz_5",
  "altabadia1",
  "Aosta_3",
]
