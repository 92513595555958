import React from "react"
import { Link } from "gatsby"

import { img } from "../components"

export function Card(props) {
  const classes = `
    relative sm:max-w-64 mx-2 my-4
    text-gray-900 bg-white
    rounded shadow-md select-none overflow-hidden
    border border-gray-400 sm:border-0
    ${props.classes}
  `.trim()

  const content = (
    <>
      <Image {...props} />
      <CardHeading {...props} />
      <CardBody>{props.children}</CardBody>
    </>
  )

  if (props.to) {
    return (
      <Link className={classes} to={props.to}>
        {content}
      </Link>
    )
  } else {
    return (
      <div className={classes} to={props.to}>
        {content}
      </div>
    )
  }
}

function Image(props) {
  if (props.image) {
    return (
      <img
        className="object-cover h-48 w-full"
        alt={props.altimage}
        src={img(props.image)}
      />
    )
  } else {
    return null
  }
}

function CardHeading(props) {
  return (
    <div className={`px-6 ${props.headClasses}`.trim()}>
      <div className="h-16 flex justify-between items-end">
        <h2 className="text-xl font-bold">{props.heading || ""}</h2>
        <Logo {...props} />
        <Icon {...props} />
      </div>
      <Ingress {...props} />
    </div>
  )
}

function CardBody(props) {
  return props.children ? (
    <div className="text-gray-800 p-6 leading-7">{props.children}</div>
  ) : null
}

function Ingress(props) {
  return props.ingress ? (
    <span className="text-gray-700 text-sm">{props.ingress}</span>
  ) : null
}

function Logo(props) {
  return props.logo || props.altlogo ? (
    <img
      className="rounded-full -mr-2 w-10 h-10 shadow"
      alt={props.altlogo}
      src={props.logo && img(props.logo)}
    />
  ) : null
}

function Icon(props) {
  return props.icon ? (
    <div className="rounded-full ml-1 -mr-2 p-1 w-10 h-10 shadow">
      {props.icon}
    </div>
  ) : null
}
