import { initImageGalleries } from "./src/components/gallery"
import { replaceWithNativeShareButton } from "./src/layout/share"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export function onRouteUpdate() {
  initImageGalleries()
  replaceWithNativeShareButton()
}
