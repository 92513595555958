import React, { useRef, useEffect, useState } from "react"

import { img } from "./cloudinary"

export function IPhone(props) {
  return (
    <IPhoneFrame {...props}>
      <img
        className={`absolute ${props.imageClass || ""}`.trim()}
        alt={props.alt}
        src={props.src || img(props.img)}
        style={{
          top: "-4.3%",
          transform: "scale(0.875)",
          ...props.imageStyle,
        }}
      />
    </IPhoneFrame>
  )
}

function IPhoneFrame(props) {
  const { className = "" } = props
  return (
    <div className={className}>
      <div className="relative flex justify-center items-center w-full">
        <img
          className="pointer-events-none"
          alt="iphone frame"
          src="/images/iphone_frame.png"
          style={{ zIndex: 1 }}
        />
        {props.children}
      </div>
      {props.text && (
        <div className="text-center text-sm text-gray-900 italic rounded">
          {props.text}
        </div>
      )}
    </div>
  )
}

export function Android(props) {
  const { className = "" } = props
  return (
    <div className={className}>
      <img
        className={`shadow-md rounded ${props.imageClass || ""}`.trim()}
        alt={props.alt}
        src={img(props.img)}
      />
      {props.text && (
        <div className="text-center text-sm mt-4 text-gray-900 italic">
          {props.text}
        </div>
      )}
    </div>
  )
}

export function IPhoneCarousel(props = {}) {
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */

  const [current, setCurrent] = useState(0)
  const imageRefs = useRef([])
  const scrollContainer = useRef()
  function scrollTo(index) {
    index = Math.min(index, props.images.length - 1)
    index = Math.max(index, 0)
    const left = imageRefs.current[index].offsetLeft
    setCurrent(index)
    scrollContainer.current.scrollTo({ top: 0, left, behavior: "smooth" })
  }

  useEffect(() => {
    setTimeout(() => scrollContainer.current.scrollTo({ top: 0, left: 0 }), 20)
  }, [])

  function handleScroll(e) {
    const positions = imageRefs.current.map((image) => image.offsetLeft)
    const index = positions.findIndex(
      (i) => i === scrollContainer.current.scrollLeft
    )
    if (index >= 0) {
      setCurrent(index)
    }
  }

  const images =
    props.images?.map?.((image, index) => {
      const { label, ...rest } = image
      return (
        <img
          alt={label || image.src}
          key={image.src}
          ref={(el) => (imageRefs.current[index] = el)}
          {...rest}
          className="mx-px"
          style={{ scrollSnapAlign: "center" }}
        />
      )
    }) || []

  const { color = "black" } = props
  const imageIndicators =
    props.images?.map?.((image, index) => {
      const active =
        index === current
          ? `w-4 h-4 bg-black bg-${color} shadow-md`
          : `w-3 h-3 border-2 border-black border-${color} shadow`
      return (
        <span
          key={index}
          className={`inline-block mx-1 cursor-pointer rounded-full ${active}`}
          onClick={() => scrollTo(index)}
        />
      )
    }) || []

  let className = `relative mx-12 sm:mx-auto sm:max-w-56 ${
    props.className || ""
  }`.trim()
  if (props.resetClass) {
    className = props.resetClass
  }

  let top = { top: "-4.2%" }
  if (props.top) {
    top = { top: props.top }
  }

  return (
    <div className={className}>
      <img
        className="relative pointer-events-none select-none"
        alt="iphone frame"
        src="/images/iphone_frame.png"
        style={{ zIndex: 1 }}
      />
      <div className="absolute w-full h-full select-none" style={top}>
        <div
          className="flex flex-nowrap overflow-x-scroll bg-white"
          onScroll={handleScroll}
          ref={scrollContainer}
          style={{ scale: "0.875", scrollSnapType: "x mandatory" }}
        >
          {images}
        </div>
      </div>
      <div className="relative flex justify-center items-center mb-6 -mt-4 z-10 select-none">
        {imageIndicators}
      </div>
      {props.images?.[current]?.label && (
        <div
          className={`flex items-center text-center text-${color} -mt-4 mb-4 px-4 rounded`}
        >
          <span
            className="absolute cursor-pointer text-2xl left-0 -ml-2 select-none"
            onClick={() => scrollTo(current - 1)}
          >
            ‹
          </span>
          {props.images[current].label}
          <span
            className="absolute cursor-pointer text-2xl right-0 ml-2 select-none"
            onClick={() => scrollTo(current + 1)}
          >
            ›
          </span>
        </div>
      )}
    </div>
  )
}
