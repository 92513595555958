import React from "react"

import { FaFacebookF, FaTwitter, FaEnvelope } from "react-icons/fa"

/**
 * This is called client-side only, not build-time.
 */
export function replaceWithNativeShareButton() {
  if (window.navigator?.share) {
    const native = document.querySelector(".native-share-buttons")
    native && native.classList.remove("hidden")
  } else {
    const share = document.querySelector(".share-buttons")
    share && share.classList.remove("hidden")
  }
}

export function Share(props) {
  return (
    <div className="flex share-buttons flex-no-wrap mt-6">
      <FacebookShare {...props} />
      <TwitterShare {...props} />
      <EmailShare {...props} />
    </div>
  )
}

function FacebookShare(props) {
  return (
    <ShareIcon
      href={`https://www.facebook.com/sharer/sharer.php?u=https://trailguide.no${props.path}`}
      icon={FaFacebookF}
      open={true}
    />
  )
}

function TwitterShare(props) {
  const text = twitterText(props)
  return (
    <ShareIcon
      href={`https://twitter.com/intent/tweet/?text=${text}&url=https://trailguide.no${props.path}`}
      icon={FaTwitter}
      open={true}
    />
  )
}

function EmailShare(props) {
  const subject = encodeURIComponent(props.heading)
  const body = encodeURIComponent(
    `${props.ingress}\n\nhttps://trailguide.no${props.path}`
  )
  return (
    <ShareIcon
      href={`mailto:?to=&subject=${subject}&body=${body}`}
      icon={FaEnvelope}
    />
  )
}

function ShareIcon(props) {
  return (
    <a
      className="text-gray-500 hover:text-blue-800 p-2 rounded-full border my-1 sm:ml-3 ml-0 mr-3 sm:mr-0 bg-white"
      href={props.href}
      target="share"
      onClick={(e) => {
        if (props.open) {
          windowPopup(props.href)
          e.preventDefault()
        }
      }}
    >
      <span className="text-lg">
        <props.icon />
      </span>
    </a>
  )
}

function windowPopup(url, width = 600, height = 600) {
  window.open(
    url,
    "",
    "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" +
      width +
      ",height=" +
      height +
      ",top=" +
      top(height) +
      ",left=" +
      left(width)
  )
}

function twitterText({ heading = "Trailguide", ingress = "Mountain biking" }) {
  return heading + ". " + ingress
}

function left(width) {
  return (window.screen && window.screen.width / 2 - width / 2) || 100
}

function top(height) {
  return (window.screen && window.screen.height / 2 - height / 2) || 100
}
