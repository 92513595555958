export function backgroundImage(props) {
  const {
    backgroundColor = "rgba(252, 247, 240, 0.7)",
    backgroundBlendMode = "lighten",
  } = props
  return props.image
    ? {
        backgroundImage: `url(https://res.cloudinary.com/trailguide-as/image/upload/c_limit,dpr_auto,w_auto:100:1200/v1590680706/${props.image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor,
        backgroundBlendMode,
      }
    : {}
}

export function backgroundClasses(props) {
  const { frontmatter } = props.pageContext
  if (frontmatter.background) {
    return frontmatter.background
  } else {
    return ""
  }
}
