import { Section } from "../../../../src/layout";
import { Gallery, Thumb, DesktopRightImage, PhoneImage } from "../../../../src/components";
import * as React from 'react';
export default {
  Section,
  Gallery,
  Thumb,
  DesktopRightImage,
  PhoneImage,
  React
};