import React from "react"

export function MapUpdateProcess() {
  return (
    <table className="w-full text-sm shadow rounded bg-white">
      <tbody>
        <tr className="bg-gray-200">
          <th className="p-4 text-left">Step</th>
          <th className="p-4 text-left">Paper map</th>
          <th className="p-4 text-left">Digitized map (PDF)</th>
          <th className="p-4 text-left">Digitalized (CMS)</th>
        </tr>
        <Row
          step="Create map data"
          paper="Graphic program required, or communication with agency, multiple iterations of changing and confirming."
          digitized="Scan print or use existing print data."
          digitalized="Only a web-browser required, easy to edit, result is visible immediately."
        />
        <Row
          step="Production"
          paper="Material intensive, expensive, time consuming."
          digitized="-"
          digitalized="-"
        />
        <Row
          step="Distribution"
          paper="Has to be sent to, or picked up by the recipient. Then distributed to the display locations."
          digitized="Upload to the website provider, create a QR-code, send out a newsletter."
          digitalized="Changes are automatically published by the CMS in the app, website plugins and QR codes."
        />
        <Row
          step="Accessibility"
          paper="Only available in a few places. Physical storages (e.g. tourist offices, map boxes) are expensive. Limited edition - reprint required."
          digitized="Through website, QR-codes, Newsletter."
          digitalized="Independent from time and location. Through the app, website plugins, QR-codes, newsletter, social media."
        />
        <Row
          step="Usage"
          paper="Maps are often only printed once a year, so the quality of information decreases. Navigation only for advanced users. Contains no additional information about the activities like description, images, status, current conditions, user reviews."
          digitized="Downloaded information can not be updated and loses quality. A PDF is just a non-interactive image, no additional info to the activities, navigation only for advanced users, no interaction with the user (feedback, conditions, status), complicated to use with on phone screens."
          digitalized="Interactive map with additional information and app functions, up to date information, see your location to navigate, user interaction possible, works on all devices."
        />
      </tbody>
    </table>
  )
}

function Row(props) {
  return (
    <tr>
      <td className="align-top p-4 font-semibold text-left">{props.step}</td>
      <td className="align-top p-4 text-left">{props.paper}</td>
      <td className="align-top p-4 text-left">{props.digitized}</td>
      <td className="align-top p-4 text-left">{props.digitalized}</td>
    </tr>
  )
}
