import React from "react"

export function Gallery(props) {
  const id = Date.now()
  const galleryId = "gallery-" + id

  /* eslint-disable */
  return (
    <div className="my-8">
      <div id={galleryId} className="blueimp-gallery blueimp-gallery-controls">
        <div className="slides"></div>
        <h3 className="title"></h3>
        <a className="description absolute w-full top-0 text-center py-4 px-12 text-white bg-black-25" />
        <a className="prev">‹</a>
        <a className="next">›</a>
        <a className="close">×</a>
        <a className="play-pause"></a>
        <ol className="indicator"></ol>
      </div>

      <div className="flex flex-wrap justify-center" id={id}>
        {props.children}
      </div>
    </div>
  )
  /* eslint-enable */
}

export function Thumb(props) {
  return (
    <a
      className="p-px sm:p-1 h-16 sm:h-24"
      href={`https://res.cloudinary.com/trailguide-as/image/upload/c_limit,dpr_auto,w_auto:100:1600/v1/${props.src}`}
      data-description={props.children}
    >
      <img
        className="h-full"
        src={`https://res.cloudinary.com/trailguide-as/image/upload/c_limit,w_200/v1/${props.src}`}
        alt={props.src}
      />
    </a>
  )
}

export function initImageGalleries() {
  const galleries = document.querySelectorAll(".blueimp-gallery")
  galleries.forEach(gallery => {
    const galleryId = gallery.getAttribute("id")
    const id = galleryId.substring("gallery-".length)
    initGallery(id)
  })
}

function initGallery(id) {
  const galleryId = "gallery-" + id
  var list = document.getElementById(id).querySelectorAll("a")
  var onslide = (index, slide) => {
    try {
      let text = list[index].getAttribute("data-description")
      const url = list[index].getAttribute("data-url")
      const container = document.querySelector("a.description")
      if (url != null && url !== "undefined") {
        text =
          '<a class="text-white hover:underline" href="' +
          url +
          '" target="trail">' +
          text +
          " [more…]</a>"
      }
      container.innerHTML = text
    } catch (error) {
      // do nothing
    }
  }

  document.getElementById(id).onclick = function(event) {
    event = event || window.event
    const container = document.getElementById(galleryId)
    var target = event.target || event.srcElement,
      link = target.src ? target.parentNode : target,
      options = { index: link, event: event, onslide: onslide, container },
      links = this.getElementsByTagName("a")
    window.blueimp && window.blueimp.Gallery(links, options)
  }
}
