/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export default function Seo(props) {
  const { description, lang, title, script, page = {} } = props
  const { heading, ingress, og_image } = page

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const body = bodyText(ingress, props.children)

  // <meta property="og:url" content="{{ facebook_og.url }}" />
  // <meta property="og:description" content="{{ facebook_og.description }}" />
  // <meta property="og:image" content="{{ facebook_og.image }}" />

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: heading || title,
        },
        {
          property: `og:description`,
          content: body || metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content:
            og_image ||
            "https://res.cloudinary.com/trailguide-as/image/upload/v1591367832/logo/trailguide-logo.jpg",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: heading || title,
        },
        {
          name: `twitter:description`,
          content: body || metaDescription,
        },
        {
          name: "google-site-verification",
          content: "BuSDJvytycvz2h9zJDUw_ufyp6jNP8Bmqf9twgjEvZo",
        },
        {
          name: `facebook-domain-verification`,
          content: `l04p2jbwkx5vq4tlr2kwnhlct2s1yh`,
        },
      ].concat()}
    >
      <script async src="/facebook.js" />
      <script async src="https://trailguide.net/js/plugin.js" />
      {script && <script async src={script} />}
      <link rel="stylesheet" href="/blueimp-gallery.css" />
      <script src="/blueimp-gallery.min.js" />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

function bodyText(ingress, children) {
  if (Array.isArray(children)) {
    const paragraphs = children
      .filter((c) => c.props && c.props.mdxType === "p")
      .map((c) => c.props.children)
    const body = paragraphs.length ? paragraphs[0] : ""
    return `
      ${ingress}.
      ${body}
    `.trim()
  } else {
    return ""
  }
}
